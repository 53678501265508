/*
Version 0.100

Made by: Finally // Aleks Schakel // www.finally.nl // aleks@finally.nl
Copyright 2016 - 2023.

!Changelog

2023
	10-03-2023
	- [BORN] This class is born. Hooray!

!Usage

	-
	
!Wishlist

	none
	
*/
  
var specific_feedback = {};

(function(){

	// PRELOAD & DOCUMENT READY  *****************************************************************
	// PRELOAD & DOCUMENT READY *****************************************************************
	// PRELOAD & DOCUMENT READY ******************************************************************
	// PRELOAD & DOCUMENT READY ******************************************************************
	
	this.onready = function() {

		$(document).on('click','.js-click-feedback-bt',function() {

			var self = this;

			var _vacature_title 	= $('h1').html();
			var _feedback 			= $(self).data('feedback');

			$(self).find('.loading').removeClass('hidden');

			specific_feedback.send(
				_vacature_title,
				_feedback
			);

		});

	};

	// METHODS *****************************************************************
	// METHODS *****************************************************************
	// METHODS *****************************************************************
	// METHODS *****************************************************************

	this.send = function(
		_vacature_title,
		_feedback
	) {

		if(_feedback == 'interesse') {

			/*
			Scroll to offset div
			*/
			$('.js-interesse').removeClass('hidden');
			$('html, body').animate({
				scrollTop: $('.js-interesse').offset().top
			}, 500);

		}

	  	/*
	  	Ajax
	  	*/
	  	$.ajax({
			url: globalUrl + '/a/a/feedback',
			type: "POST",
			data: {
				title		: _vacature_title,
				feedback	: _feedback
			},
			headers: { 
				'go-load':1 
			}
		})
		.done(function(data) {

			$('.loading').addClass('hidden');

			if(_feedback == 'interesse') {

			

			} else {

				/*
				Popup: Feedback
				*/
				popup.open(1,1,'feedback');

			}

		});


	};
			
}).apply(specific_feedback); 