/*
Version 0.110

Made by: Finally // Aleks Schakel // www.finally.nl // aleks@finally.nl
Copyright 2016 - 2018.

!Changelog

2019
	24-07-2019
	- [CHANGED] trim() added, to be sure that the error message has no spaces
2018
	11-10-2018
	- [BORN] This class is born. Hooray!

!Usage

	-
	
!Wishlist

	none
	
*/
  
var specific_interactions_formhandling = {};

(function(){
	 
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	
	/**
	None yet
	**/
	 
	// PRELOAD & DOCUMENT READY  *****************************************************************
	// PRELOAD & DOCUMENT READY *****************************************************************
	// PRELOAD & DOCUMENT READY ******************************************************************
	// PRELOAD & DOCUMENT READY ******************************************************************
	
	// none
	
	// METHODS *****************************************************************
	// METHODS *****************************************************************
	// METHODS *****************************************************************
	// METHODS *****************************************************************
	
		this.formHandlingBefore = function(
			formname
		) {
			
			switch(formname) {
			
				default:
					
					// nothing
					
				break;
		
			}
			
		};
		
		this.formHandling = function(
			formaction,
			formname,
			data
		) {

			/**
			Error
			**/
			if(data.trim().substring(0,5) === 'Error') {
				
				/**
				Catch unusual PHP message
				**/
				errorMessage = data.split('§');
				
				/**
				fadeIn error message
				**/
				specific_interactions_formhandling.error(formname,errorMessage[1]);
				
				/**
				Cancel and reset the form 
				**/
				form.cancelForm(formaction);
				
			/**
			Success
			**/
			} else {
				
				switch(formname) {
				
					default:
						
						// data
						// $('html,body').scrollTop(0); don't scroll to top!
				
						$(formaction).html(data);
						processForm = 0;

						/*
						Scroll back top
						*/
						$("html, body").animate({ scrollTop:  $(".form-action").offset().top }, "slow");

					break;

					case 'adviesgesprek-2':

						/*
						Error
						*/
						// if($('.js-form-input-planning').val() == 'none') {
						// 	alert('U moet wel invullen wat uw planning is. Probeer nogmaals, alvast bedankt.');
						// 	return false;
						// }

						$(formaction).html(data);
						processForm = 0;
					
						/*
						Google analytics
						*/
						googleanalytics.track(
							'event',
							'adviesgesprek_aanvragen_verstuurd'
						);
						
						/*
						Facebook pixel
						*/
						fbq('track', 'CompleteRegistration');

						/*
						Scroll back top
						*/
						$("html, body").animate({ scrollTop:  $(".form-action").offset().top }, "slow");

						
					break;
					
				}
			
			}
			
		};
		
		/**
		Callback: Radiobutton
		**/
		this.radiobuttonHandeling = function(t) {
			
			/**
			Catch the name
			**/
			var _this_radiobutton 			= $(t).data('name');
			var _this_radiobutton_value 	= $("input[name*=" + _this_radiobutton + "]").val();
			
			/**
			Callback
			**/
			switch(_this_radiobutton) {
				
				default:
				break;
				
			}
			
			
		};
		
		/**
		Callback: Checkbox
		**/
		this.checkboxHandeling = function(t,onoff) {
			
			/**
			Catch the name
			**/
			var _this_checkbox = $(t).data('name');
			
			/**
			Callback
			**/
			switch(_this_checkbox) {
				
				default:
				break;
				
			}
			
		};
		
		/**
		Callback: Select
		**/
		this.selectHandeling = function(t) {
			
			/**
			Catch the name
			**/
			var _this_selectbox 			= $(t).attr('name');
			var _this_selectbox_selected 	= $(t).val();
			
			/**
			Callback
			**/
			switch(_this_selectbox) {
				
				default:
				break;

				case 'category':
					if(_this_selectbox_selected == 'service') {

						$('.js-serviceaanvraag').show();

					} 

					if(_this_selectbox_selected == 'advies') {

						window.location = globalUrl + '/adviesgesprek-aanvragen';

					} 
				break;
				
			}
			
		};
		
		/**
		Upload Document / Image callback specifics
		**/
		this.uploaderCallback = function(
			callback,
			id,
			action,
			data
		) {
			
			switch(callback) {
				
				default:
				break;
				
				/*
				Image: News
				*/
				case 'image-bijlagen-1':
				
					_id = '#upload_image_bijlagen_image-1';

					if(action == 'progress') {
						$(_id + ' .js-upload-info').find('span').html('');
						$(_id + ' .js-upload-progress').show().find('span').html(data + '%');
					}

					if(action == 'uploaded') {
						if($(_id + ' .js-upload-info').find('img').length === 0) {
							$(_id + ' .js-upload-info').find('span').html('<img src="' + globalUrl + '/../img/uploads/bijlagen/' + data + '" width="100%" alt="">');
						} else {
							$(_id + ' .js-upload-info').find('img').attr('src',globalUrl + '/../img/uploads/bijlagen/' + data);
						}
						
						$(_id + ' .js-upload-progress').hide();
						$(_id + ' .js-button-delete-image').show();
					}

				break;
				
				case 'image-bijlagen-2':
				
					_id = '#upload_image_bijlagen_image-2';

					if(action == 'progress') {
						$(_id + ' .js-upload-info').find('span').html('');
						$(_id + ' .js-upload-progress').show().find('span').html(data + '%');
					}

					if(action == 'uploaded') {
						if($(_id + ' .js-upload-info').find('img').length === 0) {
							$(_id + ' .js-upload-info').find('span').html('<img src="' + globalUrl + '/../img/uploads/bijlagen/' + data + '" width="100%" alt="">');
						} else {
							$(_id + ' .js-upload-info').find('img').attr('src',globalUrl + '/../img/uploads/bijlagen/' + data);
						}
						
						$(_id + ' .js-upload-progress').hide();
						$(_id + ' .js-button-delete-image').show();
					}

				break;
				
				case 'image-bijlagen-3':
				
					_id = '#upload_image_bijlagen_image-3';

					if(action == 'progress') {
						$(_id + ' .js-upload-info').find('span').html('');
						$(_id + ' .js-upload-progress').show().find('span').html(data + '%');
					}

					if(action == 'uploaded') {
						if($(_id + ' .js-upload-info').find('img').length === 0) {
							$(_id + ' .js-upload-info').find('span').html('<img src="' + globalUrl + '/../img/uploads/bijlagen/' + data + '" width="100%" alt="">');
						} else {
							$(_id + ' .js-upload-info').find('img').attr('src',globalUrl + '/../img/uploads/bijlagen/' + data);
						}
						
						$(_id + ' .js-upload-progress').hide();
						$(_id + ' .js-button-delete-image').show();
					}

				break;

				case 'image-bijlagen-4':
				
					_id = '#upload_image_bijlagen_image-4';

					if(action == 'progress') {
						$(_id + ' .js-upload-info').find('span').html('');
						$(_id + ' .js-upload-progress').show().find('span').html(data + '%');
					}

					if(action == 'uploaded') {
						if($(_id + ' .js-upload-info').find('img').length === 0) {
							$(_id + ' .js-upload-info').find('span').html('<img src="' + globalUrl + '/../img/uploads/bijlagen/' + data + '" width="100%" alt="">');
						} else {
							$(_id + ' .js-upload-info').find('img').attr('src',globalUrl + '/../img/uploads/bijlagen/' + data);
						}
						
						$(_id + ' .js-upload-progress').hide();
						$(_id + ' .js-button-delete-image').show();
					}

				break;

				case 'image-bijlagen-5':
				
					_id = '#upload_image_bijlagen_image-5';

					if(action == 'progress') {
						$(_id + ' .js-upload-info').find('span').html('');
						$(_id + ' .js-upload-progress').show().find('span').html(data + '%');
					}

					if(action == 'uploaded') {
						if($(_id + ' .js-upload-info').find('img').length === 0) {
							$(_id + ' .js-upload-info').find('span').html('<img src="' + globalUrl + '/../img/uploads/bijlagen/' + data + '" width="100%" alt="">');
						} else {
							$(_id + ' .js-upload-info').find('img').attr('src',globalUrl + '/../img/uploads/bijlagen/' + data);
						}
						
						$(_id + ' .js-upload-progress').hide();
						$(_id + ' .js-button-delete-image').show();
					}

				break;

				case 'image-bijlagen-6':
				
					_id = '#upload_image_bijlagen_image-6';

					if(action == 'progress') {
						$(_id + ' .js-upload-info').find('span').html('');
						$(_id + ' .js-upload-progress').show().find('span').html(data + '%');
					}

					if(action == 'uploaded') {
						if($(_id + ' .js-upload-info').find('img').length === 0) {
							$(_id + ' .js-upload-info').find('span').html('<img src="' + globalUrl + '/../img/uploads/bijlagen/' + data + '" width="100%" alt="">');
						} else {
							$(_id + ' .js-upload-info').find('img').attr('src',globalUrl + '/../img/uploads/bijlagen/' + data);
						}
						
						$(_id + ' .js-upload-progress').hide();
						$(_id + ' .js-button-delete-image').show();
					}

				break;
				
			}
			
		};
		
		/**
		Upload Document / Image callback specifics delete
		**/
		this.uploaderCallbackDelete = function(
			callback,
			id
		) {
			
			switch(callback) {
				
				default:
				break;
				
			}
			
		};
		
		/**
		Error
		**/
		this.error = function(i,errormessage) { 
		
			switch(i) {
				
				default:
					modal.open('error',i,errormessage);
				break;
				
			}
		
		};
		
}).apply(specific_interactions_formhandling); 