/*
Version 0.145

Made by: Finally // Aleks Schakel // www.finally.nl // aleks@finally.nl
Copyright 2016 - 2024.

!Changelog

2024
	- [CHANGE] The setup is changed to GTM behaviour with GA4.
2019
	14-06-2019
	- [CHANGE] Something is not working with the Cookiewall variable. Disabled it for now.
	27-03-2019
	-[CHANGE] Changed all Google Analytics to Google Tag Manager option
2018
	25-05-2018
	- [ADDED] Extra check for Cookiewall 
2017
	21-09-2018
	- [METHOD] Added: Event tracking with UAC
	18-10-2017
	- [BORN] This class has born.

!Usage

	googleanalytics.track(
		'event',
		'Offerteformulier aanvraag',
		'Offerte aanvraag',
		'Aanvraag verstuurd',
		'Algemene offerte aanvraag'
	);
	
!Wishlist
 
*/
  
var googleanalytics = {};

(function(){
	
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************

	// none
	
	// DOCUMENT READY *****************************************************************
	// DOCUMENT READY *****************************************************************
	// DOCUMENT READY *****************************************************************
	// DOCUMENT READY *****************************************************************
	
	// none
	
	// FUNCTIONS *****************************************************************
	// FUNCTIONS *****************************************************************
	// FUNCTIONS *****************************************************************
	// FUNCTIONS *****************************************************************

	this.track = function(
		action,
		name
	) {
		
		//if(
		//	typeof(_cookiewall) != 'undefined' &&
		//	_cookiewall_settings[2] == 1
		//) {

			switch(action) {
				
				/*
				Pageview
				*/
				case 'pageview':
					
				break;
				
				/*
				Event
				*/
				case 'event':
					
					window.dataLayer = window.dataLayer || [];
					window.dataLayer.push({
					  'event' : name
					});
	
				break;
			
			}
			
		//}	 
		
	};

}).apply(googleanalytics);