/*
Version 0.140

Made by: Finally // Aleks Schakel // www.finally.nl // aleks@finally.nl
Copyright 2016 - 2019.

!Changelog

2019
	24-05-2019
	- [CHANGE] Popup can now be fixed, new variable introduced.
2018
	11-10-2018
	- [CHANGE] Made seperate files for the specific formHandling and Window interactions.
	28-05-2018
	- [CHANGE] The mobile menu is now closing on .init.
	25-04-2018
	- [ADDED] New functionality to poll for internet connection, and notify when connection is lost.
	13-03-2017
	- [CHANGE] Loader now returns to 0 before loading new call.
	05-02-2018
	- [ADDED] Now init() has an inbuild: 'Unbind: Unnecessary listeners' function
	31-01-2018
	- [ADDED] New method: uploaderCallback() for specific callback on upload
	- [CHANGE] form.init(int) now checks if forms must be loaded in popup or in body.
2017
	08-10-2017
	- -[METHOD] Added: formHandeling() method for specific interaction on specific forms.
	29-08-2017
	- [VAR] urlParts is now url[];
	- [METHOD] New Method: Router: loadPage on behalf of init from Router.
	02-07-2017
	- [METHOD] Added: specific_interactions.windowOrientationChange() to bind interaction to landscape or portrait mode changes.
	11-04-2017
	- [METHOD] Changed: specific_interactions.getURL is not in charge of 'not found' anymore.
	21-03-2017
	- [METHOD] Added: new methods for specific getURL t.b.v. Router Class.
2016
	06-12-2016
	- [DATEPICKER] Added: startDatepicker onclick when date selection is needed and then check type
	04-12-2016
	- [ONREADY] Added: the username bindings
	- [ONREADY] Added: the bodyfat calculate checkbox bindings
	- [DATEPICKER] Added the datepicker interaction
	- [BORN] This class is born. Hooray!

!Usage

	-
	
!Wishlist

	none
	
*/
  
var specific_interactions = {};

(function(){
	 
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	
	/**
	None yet
	**/
	 
	// PRELOAD & DOCUMENT READY  *****************************************************************
	// PRELOAD & DOCUMENT READY *****************************************************************
	// PRELOAD & DOCUMENT READY ******************************************************************
	// PRELOAD & DOCUMENT READY ******************************************************************
	
	this.beforeRoute = function(
		href,
		optionArray,
		thisObj
	) {
	
		var type = optionArray[0];
					
		/**
		Check if next URL is an popup or page or sub
		**/
		if(type === 'popup') {
			
			/*
			Catch the variables when type is popup
			*/
			var popupFixed	= optionArray[1];
			var popupDirect	= optionArray[2];
			var popupTarget	= optionArray[3];
			var popupData 	= optionArray[4];	
			
			/*
			Popup: Open
			*/
			popup.open(
				popupFixed,
				popupDirect,
				popupTarget,
				popupData
			);
			
			/*
			Go on and load the next page
			*/
			router.afterRoute(
				href,
				type
			);
			
		} else {
			
			/**
			Do some animation with the Page Loader
			**/
			specific_interactions.loader(1,1);
			
			/**
			Go on and load the next page
			**/	
			router.afterRoute(
				href,
				'page'
			);
		
		}
		
	};
	
	// METHODS *****************************************************************
	// METHODS *****************************************************************
	// METHODS *****************************************************************
	// METHODS *****************************************************************

		// SPECIFIC ******************************************************************************
		// SPECIFIC ******************************************************************************
		// SPECIFIC ******************************************************************************
		// SPECIFIC ******************************************************************************
		
		// none yet
		
		// POPUPS ******************************************************************************
		// POPUPS ******************************************************************************
		// POPUPS ******************************************************************************
		// POPUPS ******************************************************************************
		
		this.popup = function(openorclose,popup) {
			
			/**
			Open popup
			**/
			if(openorclose == 'open') {		
			
				/**
				Get: Popup type
				**/
				popupType = $('.popup').data('go-popuptype');
				
				/**
				Trigger keypress off
				**/
				//specific_interactions.keypress('off',popuptype);
				
				/*
				Init: Slideshow
				*/
				setTimeout(function() {
					
					$('.js-product-slideshow').css({'opacity':1});
					
					var product_slideshow = new Swiper('.js-product-slideshow', {
						loop:true,
						slidesPerView: 1,
						spaceBetween: 20,
						autoplay: {
							delay: 2500,
						},
						initialSlide:(url[2] - 1),
						navigation: {
						  nextEl: '.swiper-button-next',
						  prevEl: '.swiper-button-prev',
						},
						breakpoints: {
							// when window width is >= 320px
							320: {
							  slidesPerView: 1,
							  spaceBetween: 20
							},
							// when window width is >= 480px
							480: {
							  slidesPerView: 1,
							  spaceBetween: 20
							},
							// when window width is >= 640px
							640: {
							  slidesPerView: 1,
							  spaceBetween: 20
							}
						}
					});
				},1000);
					
			}
			
			/**
			Close popup
			**/
			if(openorclose == 'close') {
				
				/**
				Get: Popup type
				**/
				//popupType = $('.popup').data('go-popuptype');
				
				/**
				Trigger keypress off
				**/
				//specific_interactions.keypress('off',popuptype);
				
			}
			
				
		};
		
		// KEYPRESS ******************************************************************************
		// KEYPRESS ******************************************************************************
		// KEYPRESS ******************************************************************************
		// KEYPRESS ******************************************************************************
		
		this.keypress = function(action,type) {
			
			if(action == 'on') {
				
			}
			
			if(action == 'off') {
				
			}
			
		};
		
		this.catchKeypress = function(e,type) {
			
		};
	
		// ROUTER *****************************************************************************
		// ROUTER *****************************************************************************
		// ROUTER *****************************************************************************
		// ROUTER *****************************************************************************
		
			/**
			Router: get specific URL
			**/
			this.getURL = function() {
				
				/**
				Get URL parts
				**/
				url = getUrl();
				
				/**
				Route
				**/
				_returnUrl = '';
				_returnUrl = globalUrl + renderSubUrl();
				
				if (typeof _returnUrl !== 'undefined') {
					return _returnUrl;
				} else {
					return false;
				}
				
			};
		
			/**
			Router: loadPage
			**/
			this.loadPage = function(
				data,
				type
			) {
				
				/**
				Mainpage or Subpage?
				**/
				_loader = '.page-loader';
						
				/**
				Fade current page out and the new page in when loading is done
				**/
				$(_loader).stop().animate({'opacity':0},100,function() {
					
					setTimeout(function() {
						
						/**
						Set HTML
						**/
						$(_loader).html(data);
						
						/**
						Fade in Page Loader
						**/
						$(_loader).stop().animate({'opacity':1},200);
						
						/**
						Trigger: Resize Window
						**/
						specific_interactions_window.windowResize();
						
						/**
						Trigger: images preloader
						**/
						images.preload();
						
						/**
						Trigger: forms init
						**/
						form.init(0);
						
						/**
						Default: init();
						**/
						init();
						
					},200);
					
				});
			
			};
		
		// MOBILE MENU ***********************************************************
		// MOBILE MENU ***********************************************************
		// MOBILE MENU ***********************************************************
		// MOBILE MENU ***********************************************************
		
			this.toggleMobileMenu = function(i) {
				
				/**
				Get setting in var
				**/
				if(i === 0) {
					_mode = 'close';				
				} else {
					if(isSmartphoneMenuOpen === 0) {
						 _mode = 'open';
					} else {
						_mode = 'close';
					}
				}
				
				/**
				Interaction
				**/
				if(_mode === 'close') {
					
					/**
					Close
					**/
					isSmartphoneMenuOpen = 0;		
					
				} else {
					
					/**
					Open
					**/
					isSmartphoneMenuOpen = 1;
				  
				}
				
			};
		
		// LOADER *****************************************************************
		// LOADER *****************************************************************
		// LOADER *****************************************************************
		// LOADER *****************************************************************
		
			this.loader = function(i,option) {
				
				switch (i) {
					
					/**
					Main Loader
					**/
					default:
				
						switch(option) {
							
							default:
							case 0:
							
								/**
								Hide loader
								**/
								$('.js-loader').stop().css('width',0);
								
							break;
							
							case 1:
							
								/**
								fadeIn loader, but delay on 200ms
								**/
								$('.js-loader').css('width',0).stop().delay(200).animate({'width':$(window).width()},2000);
								
							break;
							
						}
					
				}
				
			};

		// NOTIFIER *****************************************************************
		// NOTIFIER *****************************************************************
		// NOTIFIER *****************************************************************
		// NOTIFIER *****************************************************************
				
			this.notifier = function(
				i,
				error,
				message
			) {
				
				/**
				Set coloring
				**/
				if(error == 1) {
					_backgroundColor = notifierErrorBackgroundColor;
					_textColor = notifierErrorTextColor;
				} else {
					_backgroundColor = notifierNormalBackgroundColor;
					_textColor = notifierNormalTextColor;
				}
				
				/**
				Do something with the notifier
				**/
				switch (i) {
				
					default:
					
						/**
						Set notifier colors
						**/
						$('.notifier-message').html(message).css('background-color',_backgroundColor).css('color',_textColor);
						
						/**
						fadeIn notifier
						**/
						$('.notifier').fadeIn(500,function() {
							
							setTimeout(function() {
								$('.notifier').stop().fadeOut(400);
							},1500);
							
						});
						
					break;
					
				}
				
			};
			
		// MODALS ******************************************************************************
		// MODALS ******************************************************************************
		// MODALS ******************************************************************************
		// MODALS ******************************************************************************
		
		this.modal = function(openorclose) {
			
			/**
			Open modal
			**/
			if(openorclose == 'open') {
	
			}
			
			/**
			Close modal
			**/
			if(openorclose == 'close') {
			
			}
			
				
		};
		
}).apply(specific_interactions); 