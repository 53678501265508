/*
Version 0.110

Made by: Finally // Aleks Schakel // www.finally.nl // aleks@finally.nl
Copyright 2016 - 2018.

!Changelog

2018
	08-03-2019
	- [CHANGE] Added variable check to set height and with when not given in on windowResize();
	11-10-2018
	- [BORN] This class is born. Hooray!

!Usage

	-
	
!Wishlist

	none
	
*/
  
var specific_interactions_window = {};

(function(){
	 
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	
	// none
	
	// PRELOAD & DOCUMENT READY  *****************************************************************
	// PRELOAD & DOCUMENT READY *****************************************************************
	// PRELOAD & DOCUMENT READY ******************************************************************
	// PRELOAD & DOCUMENT READY ******************************************************************
	
	// METHODS *****************************************************************
	// METHODS *****************************************************************
	// METHODS *****************************************************************
	// METHODS *****************************************************************
	
		/**
		Window: resize
		**/
		this.windowResize = function(scrolled,h,w) {
			
			/*
			Set: Variables
			*/
			if(typeof h === 'undefined') {
				h = $(window).height();
			}
			if(typeof w === 'undefined') {
				w = $(window).width();
			}
			
			
			/*
			if(h > 800) {
				
				_height_window 		= (h / 2);
				_height_spotlight 	= $('.js-spotlight-inside').height() / 2;
				
				$('.spotlight-inside').css({
					'top':(_height_window - _height_spotlight) - 57
				});
					
			} else {
				
				$('.spotlight-inside').css({
					'top':150
				});
				
			}
			*/
			
		};
		
		/**
		Window: scroll
		**/
		this.windowScroll = function(scrolled,h,w) {
			
		};
		
		
		/**
		Window: orientation
		**/
		this.windowOrientationChange = function() {
			
		};
		
}).apply(specific_interactions_window); 