/*
Version 0.100

Made by: Finally // Aleks Schakel // www.finally.nl // aleks@finally.nl
Copyright 2016 - 2018.

!Changelog

2018
	17-10-2018
	- [BORN] This class is born. Hooray!

!Usage

	-
	
!Wishlist

	none
	
*/
  
var specific_interactions_firstload = {};

(function(){
	 
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	
	/**
	None yet
	**/
	 
	// PRELOAD & DOCUMENT READY  *****************************************************************
	// PRELOAD & DOCUMENT READY *****************************************************************
	// PRELOAD & DOCUMENT READY ******************************************************************
	// PRELOAD & DOCUMENT READY ******************************************************************
	
	this.onready = function() {
		
		/**
		Trigger: modal and popup preloader
		**/
		images.preload();
		modal.onready();
		popup.onready();
		form.onready();
		if(pollConnection === 1) { poll_connection.onready(); }
		
		/**
		Trigger: onready classes
		**/
		specific_mobilemenu.onready();
		specific_landing.onready();
		specific_feedback.onready();

		// $(document).on('click','.js-gallery-start',function() {

		// 	var lightbox = new SimpleLightbox({
		// 	    $items: $('.js-lightboxgallery a'),
		// 	    history: false
		// 	});	
			
		// });

		/*
		CookieBot
		*/
		setTimeout(function() {
			$('iframe[role="presentation"]').css({
				'display':'none'
			});
		},500);

	};
	
	// METHODS *****************************************************************
	// METHODS *****************************************************************
	// METHODS *****************************************************************
	// METHODS *****************************************************************
	
	// none
	
}).apply(specific_interactions_firstload); 