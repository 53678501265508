/*
Version 0.100

Made by: Finally // Aleks Schakel // www.finally.nl // aleks@finally.nl
Copyright 2016 - 2023.

!Changelog

2023
	10-03-2023
	- [BORN] This class is born. Hooray!

!Usage

	-
	
!Wishlist

	none
	
*/
  
var specific_landing = {};

(function(){

	// PRELOAD & DOCUMENT READY  *****************************************************************
	// PRELOAD & DOCUMENT READY *****************************************************************
	// PRELOAD & DOCUMENT READY ******************************************************************
	// PRELOAD & DOCUMENT READY ******************************************************************
	
	this.onready = function() {

		$(document).on('click','.js-answer',function() {
			
			var _landing_version 	= $('.answers').data('landing');
			var _landing_answer 	= $(this).data('answer');

			/*
			Popup: Open
			*/
			popup.open(1,1,'landing-answer-' + _landing_version + '-'  + _landing_answer);

			/*
			Send: Click
			*/
			specific_landing.sendClick(
				_landing_answer,
				_landing_version
			);

		});

	};

	// METHODS *****************************************************************
	// METHODS *****************************************************************
	// METHODS *****************************************************************
	// METHODS *****************************************************************

	this.sendClick = function(
		_answer,
		_landing_version
	) {

		$.ajax({
			url: globalUrl + '/a/a/send-answer',
			type: "POST",
			data: {
				landing 	: _landing_version,
				answer		: _answer
			},
			headers: { 
				'go-load':1 
			}
		})
		.done(function(data) {


		});


	};
			
}).apply(specific_landing); 