/*
Version 0.100

Made by: Finally // Aleks Schakel // www.finally.nl // aleks@finally.nl
Copyright 2016 - 2018.

!Changelog

2018
	17-10-2018
	- [BORN] This class is born. Hooray!

!Usage

	-
	
!Wishlist

	none
	
*/
  
var specific_interactions_init = {};

(function(){
	 
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	
	/**
	None yet
	**/
	 
	// PRELOAD & DOCUMENT READY  *****************************************************************
	// PRELOAD & DOCUMENT READY *****************************************************************
	// PRELOAD & DOCUMENT READY ******************************************************************
	// PRELOAD & DOCUMENT READY ******************************************************************
	
	this.onready = function() {
		
		/**
		Unbind: Unnecessary listeners
		**/
		//$(document).off('click.cookieWallSubmit');
		//$(document).off('click.cookieWallTabs');
		//$(document).off('click.cookieBarSubmit');
		
		/*
		Close the mobilemenu always on route
		*/
		specific_mobilemenu.toggle(1);
		
		/*
		Trigger: Instagram
		*/
		//specific_instagram.onready('thumb',8,true);

		/*
		Trigger: Facebook
		*/
		//specific_facebook.onready(6,true);
		
		/*
		Landing 1 A/B test *************************
		Landing 1 A/B test *************************
		Landing 1 A/B test *************************
		Landing 1 A/B test *************************
		*/
		if(
			url[1] == 'landing-1' 
		) {
			$('header').hide();
			$('footer').hide();
		} else {
			$('header').show();
			$('footer').show();
		}

		/*
		Popups
		*/
		//if(
		//	url[1] == 'adviesgesprek-aanvragen' 
		//) {
		//	popup.open(1,1,'adviesgesprek-aanvragen');
		//}
		if(
			url[1] == 'privacy-verklaring' 
		) {
			popup.open(1,1,'privacyverklaring');
		}
		
		/*
		Set: Swiper
		*/
		var swiper_1 = new Swiper('.js-swiper-1', {
			 navigation: {
				nextEl:'.js-next',
				prevEl:'.js-prev'
			 },
			loop:true,
			slidesPerView: 2,
  			spaceBetween: 20,
			autoplay: {
				delay: 3000,
		  	},
			breakpoints: {
				// when window width is >= 320px
				1000: {
				  slidesPerView: 1,
				  spaceBetween: 20
				},
			}
		});
		
		var swiper_2 = new Swiper('.js-swiper-2', {
			 navigation: {
				nextEl:'.js-next',
				prevEl:'.js-prev'
			 },
			loop:true,
			slidesPerView: 1,
  			spaceBetween: 20,
			autoplay: {
				delay: 3000,
		  	},
			breakpoints: {
				// when window width is >= 320px
				320: {
				  slidesPerView: 1,
				  spaceBetween: 20
				},
				// when window width is >= 480px
				480: {
				  slidesPerView: 1,
				  spaceBetween: 20
				},
				// when window width is >= 640px
				640: {
				  slidesPerView: 2,
				  spaceBetween: 20
				}
			}
		});

		var swiper_team = new Swiper('.js-swiper-team', {
			 // navigation: {
			// 	nextEl:'.js-next',
			// 	prevEl:'.js-prev'
			 // },
			loop:true,
			
			slidesPerView: 5,
  			
  			spaceBetween: 20,
			
			autoplay: {
				delay: 1000,
		  	},

			breakpoints: {
				// when window width is >= 320px
				800: {
				  slidesPerView:3,
				  spaceBetween: 20
				},
				// when window width is >= 480px
				480: {
				  slidesPerView: 2,
				  spaceBetween: 20
				}
			}
		});

		var swiper_landing = new Swiper('.js-swiper-landing', {
			 // navigation: {
			// 	nextEl:'.js-next',
			// 	prevEl:'.js-prev'
			 // },
			loop:true,
			
			slidesPerView: 4,
			centeredSlides: true,
  			
  			spaceBetween: 40,
			
			autoplay: {
				delay: 2000,
		  	},

			breakpoints: {
				// // when window width is >= 320px
				// 800: {
				//   slidesPerView:3,
				//   spaceBetween: 20
				// },
				// // when window width is >= 480px
				480: {
				  slidesPerView: 4,
				  spaceBetween: 10
				}
			}
		});
		
		/*
		Colorbox
		*/
		// $(".js-slideshow").colorbox({
		// 	rel:'slideshow',
		// 	slideshow:true,
		// 	maxWidth:'95%',
		// 	maxHeight:'95%'
		// });

		$('.js-lightboxgallery a').simpleLightbox({
			history: false
		});

		$('.js-lightboxgallery1 a').simpleLightbox({
			history: false
		});
		
		/*
		Main navigation: Get and set active button 
		Main navigation: Get and set active button 
		Main navigation: Get and set active button 
		Main navigation: Get and set active button 
		*/
		$('.js-nav-item').removeClass('active');
						
		/*
		Catch URL
		*/
		var _url_active_main = '';
		
		if(url[1] !== '') { 
			_url_active_main = url[1]; 
		} else { 
			_url_active_main = './';
		}
		
		if(_url_active_main !== '') {
			$('.js-nav-item[data-go-url="' + _url_active_main + '"]').addClass('active');
		}
		
		
	};
	
	// METHODS *****************************************************************
	// METHODS *****************************************************************
	// METHODS *****************************************************************
	// METHODS *****************************************************************
	
	// none
	
}).apply(specific_interactions_init); 
