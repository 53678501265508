/*
Version 0.250

Made by: Finally // Aleks Schakel // www.finally.nl // aleks@finally.nl
Copyright 2016 - 2020.

!Changelog

2020
	10-05-2020
	- [CHANGED] Height of background is changed to 100vh
	22-04-2020
	- [CHANGED] Changed the way the popup is scrolling. Also the CSS is changed.
	
2019
	10-10-2019
	- [CHANGED] Changed all .popup widths to outerWidth's.
	15-09-2019
	- [CHANGED] setPosition for Fixed Popup now doesn't change top and margin-top again when resize window.
	17-06-2019
	- [CHANGED] Change the .off function, otherwise the Router will not work anymore.
	24-05-2019
	- [CHANGED] A lot changed on the opening of the popups: Fixed and Absolute versions.
	- [CHANGED] click on popup is now first .off() and the .on(). Why?!
	- [CHANGED] The click listener is moved to this class.
	15-05-2019
	- [ADDED] Body scroll lock library for fixing scrolling on iOS 9 mobile Safari
	14-05-2019
	- [CHANGED] Something fixed on popup; body hidden on open.
2018
	10-12-2018
	- [CHANGED] Some things changed for the scrollfunction when the popup is higher then the windowHeight.
	25-11-2018
	- [CHANGED] Changed something when loading the URL when there are more than one language available.
	04-09-2018
	- [CHANGED] Popup is now better working!
	- [CHANGED] Overflow is now: auto instead of Scroll on .js-popup-loader.
	27-08-2018
	- [CHANGED] Popup now has a delay of 500ms on behalf of setPosition.
	- [CHANGED] setPosition is now better, scroll is working again when popup is overflow.
	27-07-2018
	- [CHANGED] Popup now has a delay of 200ms on behalf of setPosition.
	27-05-2018
	- [ADDED] popup.close now checks is cookie settings are already set or not.
	23-05-2018
	- [ADDED] popup.close now checks for cookiewall popup.
	24-04-2018
	- [CHANGE] URL for popups is now checking for Language Variable.
	10-04-2018
	- [CHANGE] Something changed to setPosition, now works with .js-popup-loader.
	06-03-2018
	- [MOVED] Moved the triggers on open from specific_interaction to uit_popups.
	01-03-2018
	- [ADDED] Added ESC functionality when popup is open
	- [CHANGE] Moved the Unbind from specific_internactions to when the popup is closed
	28-02-2018
	- [ADDED] New functionality added for scrolling the popup without scrolling the body
2017
	10-10-2017
	- [TARGET] Changed all interaction targets to .js classes.
	16-02-2017
	- [CLOSE] Added: change SEO title back to old one
	- [METHOD] Changed: p2 to p to open popup
2016
	18-12-2016
	- [METHOD] Added: Specific upload code on avatar popup
	07-11-2016
	- [METHOD] Open and close now have option to hide html/body scrollbars
	05-11-2016
	- [BUILD] Build in new class formation
	26-10-2016
	- [FUNCTION] openPopup is now working with POST data instead or GET from URL 
	- [BORN] This class is born. Hooray!

!Usage

	popup.close();
	popup.open();
	
!Wishlist

	none
 
*/

var popup = {};

(function(){
	
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************

	// none
	 
	// BIND LISTENERS *****************************************************************
	// BIND LISTENERS *****************************************************************
	// BIND LISTENERS *****************************************************************
	// BIND LISTENERS *****************************************************************
	
	this.onready = function() {
		
		/**
		Navigation: Popup link
		**/
		$(document).off('click','.go-popup').on('click','.go-popup',function(e) { // no touchstart?
			
			e.preventDefault();
			
			var href 			= $(this).attr('href');
			
			var popupFixed 		= $(this).attr('data-go-popup-fixed');
			var popupTarget 	= $(this).attr('data-go-popup-target');
			var popupDirect 	= $(this).attr('data-go-popup-direct');
			var track 			= $(this).attr('data-go-track');
			
			/**
			Route
			**/
			router.beforeRoute(
				href,
				["popup",popupFixed,popupDirect,popupTarget],
				$(this)
			);
			
			/**
			Always check for Google or Piwik installation,
			then track all clicks on internal HREF's with PAGE.
			If there is an 'track' then track also the specific EVENT.
			**/
			
			/**
			Piwik: Event tracking
			**/
			if(	
				typeof track !== 'undefined' &&
				typeof _analytics_piwik_id !== 'undefined'
			) {
				piwik.track(
					'link',
					'page',
					href
				);
			}
			
			/**
			Analytics: Event tracking
			**/
			if(
				typeof track !== 'undefined' &&
				typeof _analytics_google_id !== 'undefined'
			) {
				var trackArray = track.split(',');
				googleanalytics.track(
					'event',
					trackArray[0],
					trackArray[1],
					trackArray[2]
				);
			}
			
		});
		
	};

	// FUNCTIONS *****************************************************************
	// FUNCTIONS *****************************************************************
	// FUNCTIONS *****************************************************************
	// FUNCTIONS *****************************************************************
	
	/**
	Open Popup
	**/
	this.open = function(
		fixed,
		direct,
		target,
		data,
		show
	) {
		
		var popupFixed 	= typeof fixed 	!== 'undefined' ? fixed 	: 0;
		var popupDirect = typeof direct !== 'undefined' ? direct 	: 0;
		var popupTarget = typeof target !== 'undefined' ? target 	: 0;
		var popupData 	= typeof data 	!== 'undefined' ? data 		: '';
		var popupShow 	= typeof show 	!== 'undefined' ? show 		: 'show';
		
		popupFixed 		= parseInt(popupFixed);
		popupDirect 	= parseInt(popupDirect);
		
		/**
		Build URL
		**/
		var popupUrl = '';
		
		/**
		When URL is not given in, then render Browser URL to catch popup window
		**/
		if(popupDirect === 0) {
			if(_languagesCounter == 1) {
				popupUrl = globalUrl + '/a/p' + renderSubUrl();
			} else {
				popupUrl = globalUrl + '/' + _language + '/a/p' + renderSubUrl();
			}
		} else {
			if(_languagesCounter == 1) {
				popupUrl = globalUrl + '/a/p/' + popupTarget;
			} else {
				popupUrl = globalUrl + '/' + _language + '/a/p/' + popupTarget;
			}
		}
		
		/**
		Open popup
		**/
		if(isPopupOpen === 0) {
				
			/**
			Bind Listeners: Close Popup on click and keypress: ESC
			**/
			$(document).on("keyup.closePopup",function(e) {
				if (e.keyCode == 27) {
					
					
					/**
					Only when cookiewall settings are already set
					**/
					if(	
						typeof _cookiewall_settings === 'undefined' ||
						_cookiewall_settings[0] !== 0
					) {
						popup.close();
					}
					
				}
			});
			
			$(document).on(
				'click.popupClose',
				'.js-popup-close,.js-form-cancel,.js-popup-background',
				function(e) {
				e.preventDefault();

				/**
				Only when cookiewall settings are already set
				**/
				if(
					typeof _cookiewall_settings === 'undefined' ||
					_cookiewall_settings[0] !== 0
				) {
					
					/**
					If historyURL is the same as document.URL
					**/
					if(historyURL == document.URL) {
						popup.close();
					} else {
						router.beforeRoute(historyURL,["popup",0,0]);
					}
					
				}
				
			});
			
			/**
			Add .popup-open
			**/
			if(popupFixed === 0) {
				$('body').addClass('popup-open');
			}
			
			$('.js-popup-background').fadeIn(200);
			$('.js-popup-scroller').show();
			
			/*
			Body: Overflow
			*/
			$('body').css({'overflow':'hidden'});
			
			/**
			Send data by POST call and open popup on success
			**/		
			$.ajax({
			  url:		popupUrl,
			  type: 	'POST',
			  data: 	{ obj: popupData },
			  headers: 	{ 'go-load':1 },
			  success: 	function(html) {
				
				/**
				Load the popup
				**/
				$('.js-popup-loader').hide().html(html).promise().done(function() {
					
					/**
					Set: isPopupOpen
					**/
					isPopupOpen = 1;
					
					/**
					Get: Popup type
					**/
					popupType = $('.js-popup-loader').attr('data-go-popuptype');
					
					/**
					Trigger: popup interaction
					**/
					specific_interactions.popup(
						'open',
						popupType
					);
					
					/**
					Trigger: setPosition
					**/
					setTimeout(function() {
						popup.setFirstPosition(
							popupFixed,
							popupShow
						);
					},500);
					
					/**
					Trigger: Window Resize
					**/
					specific_interactions_window.windowResize();
						
					/**
					Set: History latest popup
					**/
					historyLatestPopup = document.URL;
					
					/**
					Default: Trigger: images preloader
					**/
					images.preload();
					
					/**
					Default: Trigger: form onready();
					**/
					form.onready('popup');
					
					/**
					Cookiewall popup?
					**/
					if(popupTarget === 'cookiewall') {
						cookiewall.onready();
					}
					
				});
				
			  },
			  error: function(e) {
				
				// error
				
			  }
			});
			
		}
		
	};
	
	/**
	Close Popup
	**/
	this.close = function() {
		
		/**
		Get: Popup type
		**/
		popupType = $('.js-popup-loader').attr('data-go-popuptype');
		
		/**
		Close popup
		**/
		if(isPopupOpen === 1) {
			
			/**
			Remove listeners
			**/		
			$(document).off('click.popupClose');
			$(document).off('keyup.closePopup');
			
			/*
			Set: Body scrollbar
			*/
			//$('body').css({
			//	'overflow':'auto'
			//});
			
			/**
			Remove .popup-open
			**/
			$('body').removeClass('popup-open');
			
			/*
			Body: Overflow
			*/
			$('body').css({'overflow':'auto'});
			
			$('.js-popup-background').fadeOut(200);
			$('.js-popup-scroller').hide();
			$('.js-popup-loader').fadeOut(200, function() {
				
				/**
				Empty the .popup-loader container
				**/
				$('.js-popup-loader').empty();
				
				/**
				Trigger: Window Resize
				**/
				specific_interactions_window.windowResize();
				
				/**
				Set: isPopupOpen var to 0
				**/				
				isPopupOpen = 0;
				
				/*
				Body Scroll: Enable
				*/
				setTimeout(function() {
					bodyScrollLock.clearAllBodyScrollLocks();
				},200);
			
			});
		}
		
		/**
		Trigger: popup interaction
		**/
		specific_interactions.popup('close',popupType);
		
	};
	
	/**
	Positioning the popups by settings
	**/
	this.setFirstPosition = function(
		fixed,
		show
	) {
		
		/*
		Reset
		*/
		//$('.js-popup-scroller').css({
		//	'top'		: 0,
			/*'overflow'	:'initial'*/
		//});
		
		$('.js-popup-loader').css({
			'position'		: 'inherit',
			'width'			: 0,
			'top'			: 0,
			'margin-top'	: 0,
			'left'			: 0,
			'margin-left'	: 0
		}).show().promise().done(function() {
		
			/**
			Is popup open?
			**/
			if(isPopupOpen === 1) {
			
				/**
				Get variables
				**/
				var popupCenter = $('.js-popup-loader').attr('data-go-center');
				var popupScroll = $('.js-popup-loader').attr('data-go-scroll');
				
				/**
				Get width and height
				**/
				var popupHeight = $('.js-popup-loader').outerHeight();
				var popupWidth 	= $('.popup').outerWidth();
				
				/**
				Hide
				**/
				$('.js-popup-loader').hide();
				
				/**
				Set scrolling on heights
				**/
				if(
					popupHeight > screenHeight
				) {
					
					/**
					Set: Popup-Scroller
					**/
					//if(fixed === 0) {
						//$('.js-popup-scroller').css({
						//	'top'		: 0,
						//	'overflow'	:'auto'
						//});
					//}
					
					if(fixed === 0) {
						
						/*
						Set: Absolute (scroll inside popup)
						*/
						$('.js-popup-loader').css({
							'position'		: 'absolute',
							'top'			: 60,
							'margin-top'	: 0,
							'left'			: (screenWidth/2),
							'margin-left'	: -(popupWidth/2),
							'width'			: popupWidth
						});
						
					} else {
						
						/*
						Set: Fixed (scroll whole page)
						*/
						$('.js-popup-loader').css({
							'position'		: 'absolute',
							'top'			: 60,
							'margin-top'	: 0,
							'left'			: (screenWidth/2),
							'margin-left'	: -(popupWidth/2),
							'width'			: popupWidth
						});
						
					}
					
					/**
					Set: Extra margin
					**/
					$('.popup .content').css({'margin-bottom':80});
					
				} else {
					
					/**
					Set: Popup-Scroller
					**/
					if(fixed === 0) {
						//$('.js-popup-scroller').css({
						//	'overflow':'hidden'
						//});
					}
					
					popupWidth 	= $('.popup').outerWidth();
									
					/*
					Set: Position fixed now, because height of popup is higher then document view
					*/
					$('.js-popup-loader').css({
						'position'		: 'fixed',
						'top'			: (screenHeight/2),
						'margin-top'	: -(popupHeight/2),
						'left'			: (screenWidth/2),
						'margin-left'	: -(popupWidth/2)
					});
					
					$('.popup .content').css({
						'margin-bottom':0
					});
					
				}
				
				/**
				Set: Body scrollbar
				**/
				//$('body').css({
				//	'overflow':'hidden'
				//});
				
				/*
				Body Scroll: Disable
				*/
				if(fixed === 0) {
					setTimeout(function() {
						//bodyScrollLock.disableBodyScroll(
						//	document.querySelector(".js-popup-scroller")
						//);
					},200);
				}
				
				/*
				Set: Fixed JS
				*/
				if(fixed === 1) {
					$('.js-popup-loader').addClass('js-popup-fixed');
				}
				
				/**
				Show the popup
				**/
				if(show === 'show') {
					$('.js-popup-loader').show();
				}
				
			}
			
			/*
			Set: Background
			*/
			$('.js-popup-background').css({
				'height':'100vh'
			});
			
		});
		
	};
	
	/**
	Positioning the popups by settings
	**/
	this.setPosition = function(
	) {
		
		/**
		Is popup open?
		**/
		if(isPopupOpen === 1) {
		
			/**
			Get variables
			**/
			var popupCenter = $('.js-popup-loader').attr('data-go-center');
			var popupScroll = $('.js-popup-loader').attr('data-go-scroll');
			
			/**
			Get width and height
			**/
			var popupHeight = $('.js-popup-loader').outerHeight();
			var popupWidth 	= $('.popup').outerWidth();
			
			/*
			Fixed?
			Fixed?
			Fixed?
			Fixed?
			*/
			
			if(
				$('.js-popup-loader').hasClass('js-popup-fixed')
			) {
				
				//$('.js-popup-scroller').css({
				//	'overflow':0
				//});
				
				if(
					popupHeight > screenHeight
				) {
	
					$('.js-popup-loader').css({
						'position'		: 'absolute',
						/* 'top'		: $(window).scrollTop() + 80, */
						/* 'margin-top'	: 0, */
						'width'			: popupWidth,
						'left'			: (screenWidth/2),
						'margin-left'	: -(popupWidth/2)
					});
				
				} else {
					
					$('.js-popup-loader').css({
						'position'		: 'fixed',
						'width'			: popupWidth,
						'top'			: (screenHeight/2),
						'margin-top'	: -(popupHeight/2),
						'left'			: (screenWidth/2),
						'margin-left'	: -(popupWidth/2)
					});
					
				}
				
			/*
			Not fixed?
			Not fixed?
			Not fixed?
			Not fixed?
			*/		
			} else {
				
				/**
				Set scrolling on heights
				**/
				if(
					popupHeight > screenHeight
				) {
			
					//$('.js-popup-scroller').css({
					//	'top':$(window).scrollTop(),
					//	'overflow':'auto'
					//});
					
					if(
						popupHeight > screenHeight
					) {
		
						$('.js-popup-loader').css({
							'position'		: 'absolute',
							'width'			: popupWidth,
							'left'			: (screenWidth/2),
							'margin-left'	: -(popupWidth/2)
						});
					
					} else {
						
						$('.js-popup-loader').css({
							'position'		: 'fixed',
							'width'			: popupWidth,
							'top'			: (screenHeight/2),
							'margin-top'	: -(popupHeight/2),
							'left'			: (screenWidth/2),
							'margin-left'	: -(popupWidth/2)
						});
						
					}
						
				} else {
						
					//$('.js-popup-scroller').css({
					//	'overflow':'hidden'
					//});
		
					/*
					Set: Position fixed now, because height of popup is higher then document view
					*/
					$('.js-popup-loader').css({
						'position'		: 'fixed',
						'top'			: (screenHeight/2),
						'margin-top'	: -(popupHeight/2),
						'left'			: (screenWidth/2),
						'margin-left'	: -(popupWidth/2)
					});
			
				}
				
			}
			
			/*
			Set: Background
			*/
			$('.js-popup-background').css({
				'height':'100vh'
			});
			
		}
		
	};
		
}).apply(popup); 