/*
Version 0.110

Made by: Finally // Aleks Schakel // www.finally.nl // aleks@finally.nl
Copyright 2016 - 2018.

!Changelog

2018
	10-12-2018
	- [CHANGED] On scroll windowHeight and windowWidth dont get in var.
	06-03-2018
	- [ADDED] Added on scroll, always trigger images.preload() with timeout.
	26-02-2018
	- [ADDED] Added mobile menu close on window resize
2017
	05-09-2017
	- [METHOD] Changed: scroll to on.scroll();
	02-07-2017
	- [METHOD] Added: windowOrientationChange() to bind landscape or portrait mode changes.
	14-06-2017
	- [FUNCTIONS] Added: On resize the variable scrolledNow is also always added to the interaction method.
	23-03-2017
	- [FUNCTIONS] Changed: the interaction is now moved to the specific_interactions Class.
	30-01-2017
	- [RESIZE] Added: adjustment for popupwindows
2016
	20-10-2016
	- [BUILD] First build

!Usage
	
	windowAction.onready();
	
!Wishlist

	No wishes
 
*/
  
var windowAction = {};

(function(){
	
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************

	// none
	 
	// DOCUMENT READY *****************************************************************
	// DOCUMENT READY *****************************************************************
	// DOCUMENT READY *****************************************************************
	// DOCUMENT READY *****************************************************************

	this.onready = function() {
		
		$(window).resize(function() {
			windowAction.windowResize();
		});
		
		$(window).on('scroll',function() {	
			windowAction.windowScroll();
		});
		
		windowAction.windowResize();
		windowAction.windowScroll();
		
		/**
		Trigger: position
		**/
		popup.setPosition();
		
	};
	
	// FUNCTIONS *****************************************************************
	// FUNCTIONS *****************************************************************
	// FUNCTIONS *****************************************************************
	// FUNCTIONS *****************************************************************
	
	this.windowResize = function() {
		
		var scrolledNow = $(window).scrollTop();
		
		/**
		Get actual window height and width
		**/
		screenHeight 	= $(window).height();
		screenWidth 	= $(window).width();
		
		/**
		Call the specific interaction
		**/
		specific_interactions_window.windowResize(
			scrolledNow,
			screenHeight,
			screenWidth
		);
		
		/**
		Check orientation
		**/
		windowAction.windowOrientationChange();
		
		/**
		Trigger: position
		**/
		popup.setPosition('show');
		modal.setPosition('show');
		
		/**
		Close mobile menu
		**/
		specific_mobilemenu.close();
		
	};
	
	this.windowScroll = function() {
		
		var scrolledNow = $(window).scrollTop();
		
		/**
		Get actual window height and width
		**/
		screenHeight 	= $(window).height();
		screenWidth 	= $(window).width();
		
		/**
		Trigger preload
		**/
		setTimeout(function() {
			images.preload();
		},200);
		
		/**
		Call the specific interaction
		**/
		specific_interactions_window.windowScroll(
			scrolledNow,
			screenHeight,
			screenWidth
		);
		
	};
	
	
	this.windowOrientationChange = function() {
		
		/**
		Check orientation
		**/
		if(window.innerHeight > window.innerWidth){
			_orientation = 'portrait';
		} else {
			_orientation = 'landscape';
		}
		
		/**
		Switch orientation
		**/
		switch(_orientation) {
					
			case 'landscape':
				if(
				isOrientation === 0 ||
				isOrientation == 'portrait'
				) {
					isOrientation = 'landscape';
				}
			break;
			
			case 'portrait':
				if(
				isOrientation === 0 ||
				isOrientation == 'landscape'
				) {
					isOrientation = 'portrait';
				}
			break;
		
		
		}
		
		/**
		Call the specific interaction
		**/
		specific_interactions_window.windowOrientationChange();
		
	};

}).apply(windowAction); 